import React from 'react';
import GlobalNavbar from './components/navbar/GlobalNavbar';
import AboutPage from './components/about/AboutPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/homepage/Homepage';
import FoodDeserts from './components/foodDeserts/foodDeserts';
import FoodResources from './components/FoodResources/FoodResources';
import ServiceProviders from './components/serviceProviders/serviceProviders';
import 'bootstrap/dist/css/bootstrap.min.css'
import ServiceProviderDetail from './components/serviceProvidersDetail/ServiceProviderDetail';
import FoodResourceDetail from './components/foodResourcesDetail/foodResourcesDetail';
import FoodDesertDetail from './components/foodDesertsDetail/foodDesertsDetail';

function App() {
  return (
    <Router>
    <div className="App" id="App">
      <GlobalNavbar />
        <div style={{ paddingTop: '70px' }} id="content">
          <Routes>
            <Route path="/" element={<Homepage/>} id="homepage"/>
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/food-deserts" element={<FoodDeserts />} />
            <Route path="/food-deserts/:id" element={<FoodDesertDetail />} />
            <Route path="/food-resources" element={<FoodResources />} />
            <Route path="/food-resources/:id" element={<FoodResourceDetail />} />
            <Route path="/service-providers" element={<ServiceProviders />} />
            <Route path="/service-providers/:serviceProviderID" element={<ServiceProviderDetail />} />

          </Routes>
        </div>
    </div>
    </Router>
  );
}

export default App;
