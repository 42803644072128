import React, { useEffect, useState } from 'react';
import { Container, Row, Card, Image } from 'react-bootstrap';
import axios from 'axios';
import './AboutPage.css';

// don't really want to hardcode these but I don't want to deal with .env this phase
const GITLAB_PROJECT_ID = '61948587';
const GITLAB_API_TOKEN = 'glpat-9Ben6seSevKRyD1YW-pS';

const teamMembers = [
    {
        name: "Jack Walker",
        photo: "/images/jack_headshot.png",
        bio: "Jack will graduate UTCS in December of 2024 and will be joining Amazon as a software developer.",
        responsibilities: "Full-Stack development, cloud infrastructure setup in AWS and Gitlab CI.",
        gitlabUsername: "j2walk",
        gitlabName: "Jack Walker",
        commits: 0,
        issues: 0,
        unitTests: 0
    },
    {
        name: "Ian Tran",
        photo: "/images/ian_headshot.png",
        bio: "Ian is currently pursuing a Bachelor of Science degree, with a focus on enhancing my technical skills in software development.",
        responsibilities: "RESTful API and Backend Development",
        gitlabUsername: "tran.ian.c",
        gitlabName: "Ian Tran",
        commits: 0,
        issues: 0,
        unitTests: 0
    },
    {
        name: "Bryan Zhao",
        photo: "/images/bryan_headshot.png",
        bio: "Bryan is passionate about UI/UX and data visualization. He is also looking to continue pursue a master’s degree in CS.",
        responsibilities: "UI/UX design and web scraping/data cleanup",
        gitlabUsername: "zhiyuan.zhao1",
        gitlabName: "PatrickBladeeman",
        commits: 0,
        issues: 0,
        unitTests: 0
    },
    {
        name: "Vincent Dang",
        photo: "/images/vincent_headshot.png",
        bio: "Vincent is a junior at UT with a passion for technology. He is currently pursuing a degree in CS.",
        responsibilities: "Data Models and Backend Development",
        gitlabUsername: "DangV",
        gitlabName: "Vincent Dang",
        commits: 0,
        issues: 0,
        unitTests: 0
    },
    {
        name: "Ayush Aggarwal",
        photo: "/images/ayush_headshot.png",
        bio: "Ayush is a sophomore in UTCS, with a passion for theory and an interest in natural language models.",
        responsibilities: "Frontend design, user stories, and documetation",
        gitlabUsername: "ayushaggarwal8",
        gitlabName: "Ayush Aggarwal",
        commits: 0,
        issues: 0,
        unitTests: 0
    }
];

const AboutPage = () => {
    const [membersData, setMembersData] = useState(teamMembers);

    const fetchCommits = async () => {
    const allCommits = [];
    let page = 1;
    const perPage = 100; // Set the maximum number of commits per page

    try {
        while (true) {
            const response = await axios.get(
                `https://gitlab.com/api/v4/projects/${GITLAB_PROJECT_ID}/repository/commits`,
                {
                    headers: {
                        'Private-Token': GITLAB_API_TOKEN
                    },
                    params: {
                        page: page,
                        per_page: perPage
                    }
                }
            );

            const commits = response.data;

            if (commits.length === 0) {
                // No more commits to fetch
                break;
            }

            allCommits.push(...commits); // Add the fetched commits to the array
            page++; // Move to the next page
        }

        console.log(`Total commits fetched: ${allCommits.length}`);
        return allCommits;
    } catch (error) {
        console.error('Error fetching commits:', error);
        return [];
    }
};


    const fetchIssues = async () => {
        const allIssues = [];
        let page = 1;
        const perPage = 100; // Set this to the maximum (100)
    
        try {
            while (true) {
                const response = await axios.get(
                    `https://gitlab.com/api/v4/projects/${GITLAB_PROJECT_ID}/issues`,
                    {
                        headers: {
                            'Private-Token': GITLAB_API_TOKEN
                        },
                        params: {
                            page: page,
                            per_page: perPage
                        }
                    }
                );
    
                const issues = response.data;
    
                if (issues.length === 0) {
                    // No more issues to fetch
                    break;
                }
    
                allIssues.push(...issues); // Add the fetched issues to the array
                page++; // Move to the next page
            }
    
            console.log(`Total issues fetched: ${allIssues.length}`);
            return allIssues;
        } catch (error) {
            console.error('Error fetching issues:', error);
            return [];
        }
    };
    

    const countIssuesForMember = (issues, authorUsername) => {
        return issues.filter(issue => issue.author.username === authorUsername).length;
    };

    const countCommitsForMember = (commits, authorName) => {
        return commits.filter(commit => commit.author_name === authorName).length;
    };

    useEffect(() => {
        const fetchCommitsForAllMembers = async () => {
            const commits = await fetchCommits();
            const issues = await fetchIssues();

            const updatedMembersData = membersData.map(member => {
                const commitCount = countCommitsForMember(commits, member.gitlabName);
                const issuesCount = countIssuesForMember(issues, member.gitlabUsername);
                return { ...member, commits: commitCount, issues: issuesCount };
            });

            setMembersData(updatedMembersData);
        };

        fetchCommitsForAllMembers();
    }, []);

    return (
        <Container fluid id="about-container">
            {/* Hero Section */}
            <section className="about-hero">
                <div className='about-hero-div'>
                    <h1>Our Mission</h1>
                    <p>
                        Building a better tomorrow by tackling food deserts in Texas. Empowering communities,
                        providing resources, and supporting local initiatives.
                    </p>
                </div>
            </section>

            <hr className='hr-break'/>

            { /* Project Mission Statement Section */}
            <section className="about-mission">
                <div>
                    <h2>Project Problem</h2>
                    <ul>
                        <li>
                            Food deserts are areas where residents have limited access to affordable, nutritious food, particularly fresh fruits and vegetables.
                        </li>
                        <li>
                            In Texas, this problem affects both rural areas and low-income urban neighborhoods.
                        </li>
                        <li>
                            This lack of access contributes to higher rates of obesity, diabetes, and other health-related issues.
                        </li>
                        <li>
                            Our project is to provide a website for users to locate food deserts and resources for those living in food deserts in texas.
                        </li>
                    </ul>
                </div>
            </section>

            <hr className='hr-break'/>

            {/* Data Sources & Tools Section - Grid Layout */}
            <section className="about-data">
                <h2>Data Sources</h2>
                <div className="data-grid-section">
                    <a href="https://www.ers.usda.gov/data-products/food-access-research-atlas/go-to-the-atlas/" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>USDA Economic Research Service</h3>
                        </div>
                    </a>
                    <a href="https://www.usdalocalfoodportal.com/fe/datasharing/" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>USDA Food Portal</h3>
                        </div>
                    </a>
                    <a href="https://map.feedingamerica.org/county/2021/overall/texas" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>Feeding America</h3>
                        </div>
                    </a>
                    <a href="https://data.texas.gov/dataset/Registered-Vehicles-by-County/j5fk-64au/about_data" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>Texas Government Vehicle Data</h3>
                        </div>
                    </a>
                    <a href="https://www.census.gov/data/developers/data-sets.html" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>US Census</h3>
                        </div>
                    </a>
                    <a href="https://developers.google.com/maps" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>Google Maps API</h3>
                        </div>
                    </a>
                    <a href="https://www.centraltexasfoodbank.org/food-assistance/get-food-now" target="_blank" rel="noopener noreferrer" className="data-link">
                        <div className="data-grid-item">
                            <h3>Central Texas Food Bank</h3>
                        </div>
                    </a>
                </div>
            </section>

            <hr className='hr-break'/>

            {/* Data Sources & Tools Section - Grid Layout */}
            <section className="about-tools">
                <h2>Tools</h2>
                <div className="tools-grid-section">
                    <div className="tools-grid-item item1">
                        <div className="tools-logo-container">
                            <Image src={"/images/aws_logo.png"} alt="AWS Logo" className="tools-logo" />
                        </div>
                        <h3>AWS</h3>
                    </div>
                    <div className="tools-grid-item item2">
                        <div className="tools-logo-container">
                            <Image src={"/images/lightsail_logo.png"} alt="Lightsail Logo" className="tools-logo" />
                        </div>
                        <h3>AWS Lightsail</h3>
                    </div>
                    <div className="tools-grid-item item3">
                        <div className="tools-logo-container">
                            <Image src={"/images/docker_logo.png"} alt="Docker Logo" className="tools-logo" />
                        </div>
                        <h3>Docker</h3>
                    </div>
                    <div className="tools-grid-item item4">
                        <div className="tools-logo-container">
                            <Image src={"/images/nginx_logo.png"} alt="Nginx Logo" className="tools-logo" />
                        </div>
                        <h3>Nginx</h3>
                    </div>
                    <div className="tools-grid-item item5">
                        <div className="tools-logo-container">
                            <Image src={"/images/react_logo.png"} alt="React Logo" className="tools-logo" />
                        </div>
                        <h3>React</h3>
                    </div>
                    <div className="tools-grid-item item6">
                        <div className="tools-logo-container">
                            <Image src={"/images/python_logo.png"} alt="Python Logo" className="tools-logo" />
                        </div>
                        <h3>Python</h3>
                    </div>
                </div>
            </section>

            <hr className='hr-break'/>

            <section className="about-team">
    <h2>Meet Our Team</h2>
    <div className="team-grid">
        {membersData.slice(0, 3).map((member, index) => (
            <div key={index} className="team-card">
                <Image src={member.photo} alt={member.name} roundedCircle className="team-image" />
                <div className="card-body">
                    <h5 className='team-name'>{member.name}</h5>
                    <p>{member.bio}</p>
                    <p className="team-responsibilities">
                        <strong>Responsibilities:</strong> {member.responsibilities}
                    </p>
                    <ul className="team-stats">
                        <li>Commits: {member.commits}</li>
                        <li>Issues: {member.issues}</li>
                        <li>Unit Tests: {member.unitTests}</li>
                    </ul>
                </div>
            </div>
        ))}
    </div>

    <div className="team-grid">
        {membersData.slice(3).map((member, index) => (
            <div key={index} className="team-card">
                <Image src={member.photo} alt={member.name} roundedCircle className="team-image" />
                <div className="card-body">
                    <h5 className='team-name'>{member.name}</h5>
                    <p>{member.bio}</p>
                    <p className="team-responsibilities">
                        <strong>Responsibilities:</strong> {member.responsibilities}
                    </p>
                    <ul className="team-stats">
                        <li>Commits: {member.commits}</li>
                        <li>Issues: {member.issues}</li>
                        <li>Unit Tests: {member.unitTests}</li>
                    </ul>
                </div>
            </div>
        ))}
    </div>
</section>

        </Container>
    );
};

export default AboutPage;
