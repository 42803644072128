import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BsPeopleFill, BsCashStack, BsMapFill, BsBuildings, BsTree, BsGlobe, BsCardChecklist, BsTelephone, BsClock, BsGeoAlt, BsHouse } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FoodDesertDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const initialDesert = location.state?.desert;

    console.log(initialDesert);

    const [foodDesert, setFoodDesert] = useState(initialDesert);
    const [loading, setLoading] = useState(!initialDesert);
    const [serviceProviderLoading, setServiceProviderLoading] = useState(true);
    const [foodResourceLoading, setFoodResourceLoading] = useState(true);

    const [error, setError] = useState(null);

    const [serviceProviderConnectionID, setServiceProviderConnectionID] = useState(foodDesert ? foodDesert.nearest_service_provider_id : -1);
    const [foodResourceConnectionID, setFoodResourceConnectionID] = useState(foodDesert ? foodDesert.nearest_food_resource_place_id : -1);
    const [serviceProviderConnection, setServiceProviderConnection] = useState(null);
    const [foodResourceConnection, setFoodResourceConnection] = useState(null);


    const fetchFoodDesertDetail = async () => {
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-deserts/${id}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch food desert details.');
            }
            const data = await response.json();
            setFoodDesert(data);
            setServiceProviderConnectionID(data.nearest_service_provider_id);
            setFoodResourceConnectionID(data.nearest_food_resource_place_id)
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    const fetchServiceProviderConnection = async () => {
        setServiceProviderLoading(true);
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/service-providers/${serviceProviderConnectionID}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch food desert connection.');
            }
            const data = await response.json();
            console.log("Fetched food desert connection: " + data);
            setServiceProviderConnection(data);
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setServiceProviderLoading(false);
        }
    };

    const fetchFoodResourceConnection = async () => {
        setFoodResourceLoading(true);
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-resources/${foodResourceConnectionID}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch food resource connection.');
            }
            const data = await response.json();
            console.log("Fetched food resource connection: " + data);
            setFoodResourceConnection(data);
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setFoodResourceLoading(false);
        }
    };

    const renderOperatingHoursTable = (hours) => {
        if (!hours) {
            return null;
        }
        const days = [
            { full: 'Monday', short: 'M' },
            { full: 'Tuesday', short: 'T' },
            { full: 'Wednesday', short: 'W' },
            { full: 'Thursday', short: 'Th' },
            { full: 'Friday', short: 'F' },
            { full: 'Saturday', short: 'Sa' },
            { full: 'Sunday', short: 'Su' },
        ];

        return (
            <table className="operating-hours-table">
                <tbody>
                    {days.map((day) => {
                        const regex = new RegExp(`${day.full}:\\s*([\\d:APM\\s–]+)`, 'i');
                        const match = hours.match(regex);
                        return (
                            <tr key={day.full}>
                                <td>{day.full}</td>
                                <td>{(match && match[1] != ' ') ? match[1] : 'Closed'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    // Function to format phone numbers
    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';

        // Remove any characters that are not digits
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the phone number has the correct length (10 digits for US numbers)
        if (cleaned.length !== 10) {
            return 'Invalid phone number';
        }

        // Format the phone number as (111)-111-1111
        const formatted = `(${cleaned.substring(0, 3)})-${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;

        return formatted;
    };

    /* global google */
    const FoodDesertMap = ({ foodDesertConnection, center = { lat: 30.2672, lng: -97.7431 }, height = '300px' }) => {
        useEffect(() => {
            function initMap() {
                if (typeof google === 'undefined' || !google.maps) {
                    console.error('Google Maps API is not loaded.');
                    return;
                }

                var map = new google.maps.Map(document.getElementById(`map-${foodDesertConnection.CensusTract}`), {
                    zoom: 10,
                    center: center,
                });

                try {
                    const feature = JSON.parse(foodDesertConnection.coordinates);

                    if (!feature || !feature.geometry) {
                        console.error('Invalid GeoJSON feature.');
                        return;
                    }

                    // Load the GeoJSON feature
                    map.data.addGeoJson({
                        type: 'FeatureCollection',
                        features: [feature]
                    });

                    // Style the specific tract feature
                    map.data.setStyle({
                        fillColor: 'blue',
                        strokeWeight: 1,
                        fillOpacity: 0.2,
                        strokeColor: 'black',
                    });

                    // Highlight the specific tract if available
                    map.data.forEach((feature) => {
                        const bounds = new google.maps.LatLngBounds();
                        feature.getGeometry().forEachLatLng((latLng) => {
                            bounds.extend(latLng);
                        });
                        map.fitBounds(bounds);
                    });

                } catch (error) {
                    console.error('Failed to parse nearestFoodDesert as JSON.', error);
                }
            }

            const loadGoogleMapsScript = () => {
                if (!window.google) {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
                    script.async = true;
                    script.defer = true;
                    script.id = 'google-maps-script';
                    document.head.appendChild(script);
                    script.onload = initMap;
                } else {
                    initMap(); // Initialize if the script is already loaded
                }
            };

            loadGoogleMapsScript();
        }, [foodDesertConnection, center]);
        return <div id={`map-${foodDesertConnection.CensusTract}`} style={{ width: '100%', height: height }} className="rounded"></div>;
    };


    useEffect(() => {
        if (!foodDesert) {
            fetchFoodDesertDetail();
        }
    }, [id, foodDesert]);

    useEffect(() => {
        if (!serviceProviderConnection && serviceProviderConnectionID != -1) {
            fetchServiceProviderConnection(); // Fetch data only if not passed via state
        }
    }, [serviceProviderConnection, serviceProviderConnectionID]);

    useEffect(() => {
        if (!foodResourceConnection && foodResourceConnectionID != -1) {
            fetchFoodResourceConnection(); // Fetch data only if not passed via state
        }
    }, [foodResourceConnectionID, foodResourceConnection]);


    if (loading) {
        return <Skeleton count={5} />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!foodDesert) {
        return <div>No data found</div>;
    }

    const calculateDangerLevel = (foodDesert) => {
        const { PovertyRate, lakids1share, laseniors1share, LALOWI1_20 } = foodDesert;
        let dangerScore = 0;

        // Danger score based on contributing factors
        dangerScore += PovertyRate * 0.5;
        dangerScore += lakids1share * 0.2;
        dangerScore += laseniors1share * 0.2;
        dangerScore += LALOWI1_20 * 0.1;

        // Normalize to 0-10 scale
        dangerScore = Math.min(10, Math.max(0, dangerScore / 10));

        // Determine qualitative level and corresponding color
        if (dangerScore <= 2) return { level: "Low", color: "green" };
        if (dangerScore <= 4) return { level: "Moderately Low", color: "lightgreen" };
        if (dangerScore <= 6) return { level: "Moderate", color: "yellow" };
        if (dangerScore <= 8) return { level: "Moderately High", color: "orange" };
        return { level: "High", color: "red" };
    };

    return (
        <Container className="my-5">
            <Row>
                <Col md={8}>
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <div style={{ height: '300px', width: '100%' }}>
                                        <FoodDesertMap className="food-desert-detail-map" id={foodDesert.CensusTract} foodDesertConnection={foodDesert} />
                                    </div>
                                </Col>
                                <Col md={6} className="d-flex flex-column justify-content-center">
                                    <div className="my-3" id="food-desert-detail-county">
                                        <BsGlobe className="me-2 mb-1" />
                                        <strong>County:</strong> {foodDesert.County}
                                    </div>
                                    <div className="my-3" id="food-desert-detail-population">
                                        <BsPeopleFill className="me-2 mb-1" />
                                        <strong>Population:</strong> {new Intl.NumberFormat().format(foodDesert.POP2010)}
                                    </div>
                                    <div className="my-3" id="food-desert-detail-income">
                                        <BsCashStack className="me-2 mb-1" />
                                        <strong>Median Family Income:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(foodDesert.MedianFamilyIncome)}
                                    </div>
                                    <div className="my-3" id="food-desert-detail-poverty">
                                        <BsMapFill className="me-2 mb-1" />
                                        <strong>Poverty Rate:</strong> {parseFloat(foodDesert.PovertyRate).toFixed(2)}%
                                    </div>
                                    <div className="my-3" id="food-desert-detail-area-type">
                                        {foodDesert.Urban ? <BsBuildings className="me-2 mb-1" /> : <BsTree className="me-2 mb-1" />}
                                        <strong>Area Type:</strong> {foodDesert.Urban ? 'Urban' : 'Rural'}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Stats Box */}
                <Col md={4}>
                    <Card className="shadow mb-4" id="stats">
                        <Card.Body>
                            <Card.Title as="h3"  className="text-center">Stats</Card.Title>
                            <div className="my-3">
                                <strong>Low Income & Low Access (1 mile, 20%):</strong> {foodDesert.LALOWI1_20}
                            </div>
                            <div className="my-3">
                                <strong>Children with Low Access (1 mile):</strong> {parseFloat(foodDesert.lakids1share).toFixed(2)}%
                            </div>
                            <div className="my-3">
                                <strong>Seniors with Low Access (1 mile):</strong> {parseFloat(foodDesert.laseniors1share).toFixed(2)}%
                            </div>
                            <div className="my-3">
                                <strong>People Receiving SNAP:</strong> {foodDesert.TractSNAP}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Danger Level Box */}
                <Col md={4}>
                    <Card className="shadow mb-4" id = "danger-level">
                        <Card.Body>
                            <Card.Title as="h3" className="text-center">Danger Level</Card.Title>
                            <div className="my-3 d-flex align-items-center">
                                <strong>Food Desert Danger Level:</strong>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: calculateDangerLevel(foodDesert).color,
                                        marginLeft: '10px',
                                        borderRadius: '4px'
                                    }}
                                ></span>
                                <span style={{ marginLeft: '10px' }}>
                                    {calculateDangerLevel(foodDesert).level}
                                </span>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Card.Title as="h3" className="text-center" id="service-provider">Nearest Service Provider</Card.Title>
                            {serviceProviderLoading ? // Show skeletons when loading
                                <div id="provider-card" >
                                    <div id="card-content">
                                        <h5 id="card-title">
                                            <Skeleton width={200} />
                                        </h5>
                                        <div id="card-details">
                                            <p>
                                                <BsHouse className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsCardChecklist className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsTelephone className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsGlobe className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsClock className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsGeoAlt className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div
                                    id="provider-card"
                                    key={serviceProviderConnection.id}
                                    onClick={() => navigate(`/service-providers/${serviceProviderConnection.id}`, { state: { serviceProviderConnection } })}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div id="card-content">
                                        <h5 id="card-title">{serviceProviderConnection.resource_name}</h5>
                                        <div id="card-details">
                                            <p>
                                                <BsCardChecklist className="icon" />
                                                <strong>Benefits:</strong> &nbsp;
                                                {serviceProviderConnection.benefit_programs}
                                            </p>
                                            <p>
                                                <BsTelephone className="icon" />
                                                <strong>Phone:</strong> &nbsp; {formatPhoneNumber(serviceProviderConnection.phone_number) || 'N/A'}
                                            </p>
                                            <p>
                                                <BsGlobe className="icon" />
                                                <strong>Website:</strong> &nbsp;
                                                <a
                                                    href={serviceProviderConnection.website}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {serviceProviderConnection.website || 'N/A'}
                                                </a>
                                            </p>
                                            <p>
                                                <BsClock className="icon" />
                                                <strong>Hours:</strong> &nbsp;
                                                {renderOperatingHoursTable(serviceProviderConnection.operating_hours) || 'N/A'}
                                            </p>
                                            <p>
                                                <BsGeoAlt className="icon" />
                                                <strong>Address:</strong> &nbsp; {serviceProviderConnection.physical_address || 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </div>}
                        </Card.Body>
                    </Card>
                </Col>


                <Col md={4}>
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Card.Title as="h3" className="text-center" id="food-resource">Nearest Food Resource</Card.Title>
                            {foodResourceLoading ? // Show skeletons when loading
                                <div id="provider-card">
                                    <div id="card-content">
                                        <h5 id="card-title">
                                            <Skeleton width={200} />
                                        </h5>
                                        <div id="card-details">
                                            <p>
                                                <BsHouse className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsCardChecklist className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsTelephone className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsGlobe className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsClock className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                            <p>
                                                <BsGeoAlt className="icon" />
                                                <Skeleton width="80%" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : // Show actual data
                                <div
                                    id="provider-card"
                                    key={foodResourceConnection.id}
                                    onClick={() => navigate(`/food-resources/${foodResourceConnection.place_id}`)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div id="card-content">
                                        <h5 id="card-title">{foodResourceConnection.name}</h5>
                                        <div id="card-details">
                                            <div style={{ marginBottom: '10px' }}>
                                                <img
                                                    src={foodResourceConnection.featured_image}
                                                    alt="No Image Available"
                                                    loading="lazy"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                                />
                                            </div>
                                            <p>
                                                <BsHouse className="icon" />
                                                <strong>Resource Type:</strong> &nbsp;
                                                {foodResourceConnection.categories}
                                            </p>
                                            <p>
                                                <BsCardChecklist className="icon" />
                                                <strong>Rating:</strong>&nbsp;
                                                {foodResourceConnection.rating}
                                            </p>
                                            <p>
                                                <BsTelephone className="icon" />
                                                <strong>Phone: </strong> &nbsp;
                                                {foodResourceConnection.phone}
                                            </p>
                                            <p>
                                                <BsGlobe className="icon" />
                                                <strong>Website:</strong>&nbsp;
                                                <a
                                                    href={foodResourceConnection.website}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {foodResourceConnection.website}
                                                </a>
                                            </p>
                                            <p>
                                                <BsClock className="icon" />
                                                <strong>Service Hours:</strong> &nbsp;
                                                {foodResourceConnection.workday_timing}
                                            </p>
                                            <p>
                                                <BsGeoAlt className="icon" />
                                                <strong>Address: </strong> &nbsp;
                                                {foodResourceConnection.address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FoodDesertDetail;
