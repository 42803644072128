import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    Badge,
    ListGroup,
    Image,
} from 'react-bootstrap';
import {
    BsTelephone,
    BsGeoAlt,
    BsClock,
    BsGlobe,
    BsFacebook,
    BsTwitter,
    BsInstagram,
    BsMapFill,
    BsPeopleFill,
    BsCashStack,
    BsCash,
    BsPeople,
    BsCarFront,
    BsBuildings,
    BsBriefcaseFill,
    BsGeoAltFill,
    BsTelephoneFill
} from 'react-icons/bs';
import { FaFax, FaDeaf, FaMap } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ServiceProviderDetail = () => {
    const { serviceProviderID } = useParams(); // Get the service provider ID from the route params
    const navigate = useNavigate();
    const location = useLocation(); // Get the data passed via navigate

    // Check if the service provider data is passed via navigate
    const initialProvider = location.state?.provider || null;

    const [serviceProvider, setServiceProvider] = useState(initialProvider);
    const [loading, setLoading] = useState(!initialProvider);
    const [foodDesertLoading, setFoodDesertLoading] = useState(true);
    const [foodResourceLoading, setFoodResourceLoading] = useState(true);
    const [error, setError] = useState(null);

    const [foodDesertConnectionID, setFoodDesertConnectionID] = useState(initialProvider ? initialProvider.nearest_food_desert_id : null);
    const [foodResourceConnectionID, setFoodResourceConnectionID] = useState(initialProvider ? initialProvider.nearest_food_resource_place_id : null);

    const [foodDesertConnection, setFoodDesertConnection] = useState(null);
    const [foodResourceConnection, setFoodResourceConnection] = useState(null);

    // Fetch service provider details from backend if not provided via location.state
    const fetchServiceProviderDetail = async () => {
        setLoading(true);
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/service-providers/${serviceProviderID}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch service provider details.');
            }
            const data = await response.json();
            setServiceProvider(data);
            setFoodDesertConnectionID(data.nearest_food_desert_id);
            setFoodResourceConnectionID(data.nearest_food_resource_place_id);
        } catch (error) {
            console.error("Error fetching service provider:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Utility function to abbreviate operating hours
    const renderOperatingHoursTable = (hours) => {
        if (!hours) {
            return null;
        }
        const days = [
            { full: 'Monday', short: 'M' },
            { full: 'Tuesday', short: 'T' },
            { full: 'Wednesday', short: 'W' },
            { full: 'Thursday', short: 'Th' },
            { full: 'Friday', short: 'F' },
            { full: 'Saturday', short: 'Sa' },
            { full: 'Sunday', short: 'Su' },
        ];

        return (
            <table className="operating-hours-table">
                <tbody>
                    {days.map((day) => {
                        const regex = new RegExp(`${day.full}:\\s*([\\d:APM\\s–]+)`, 'i');
                        const match = hours.match(regex);
                        return (
                            <tr key={day.full}>
                                <td>{day.full}</td>
                                <td>{(match && match[1] != ' ') ? match[1] : 'Closed'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const fetchFoodDesertConnection = async () => {
        if (!foodDesertConnectionID) return;
        setFoodDesertLoading(true);
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-deserts/${foodDesertConnectionID}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch food desert connection.');

            const data = await response.json();
            setFoodDesertConnection(data);
        } catch (error) {
            console.error("Error fetching food desert connection:", error);
            setError(error.message);
        } finally {
            setFoodDesertLoading(false);
        }
    };

    const fetchFoodResourceConnection = async () => {
        if (!foodResourceConnectionID) return;
        setFoodResourceLoading(true);
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-resources/${foodResourceConnectionID}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch food resource connection.');

            const data = await response.json();
            setFoodResourceConnection(data);
        } catch (error) {
            console.error("Error fetching food resource connection:", error);
            setError(error.message);
        } finally {
            setFoodResourceLoading(false);
        }
    };

    // Function to format phone numbers
    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';

        // Remove any characters that are not digits
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the phone number has the correct length (10 digits for US numbers)
        if (cleaned.length !== 10) {
            return 'Invalid phone number';
        }

        // Format the phone number as (111)-111-1111
        const formatted = `(${cleaned.substring(0, 3)})-${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;

        return formatted;
    };

    const TagsComponent = ({ nearbyFoodDeserts }) => {
        if (!nearbyFoodDeserts) return null;
        return (
            <div className="my-3">
                {nearbyFoodDeserts.GroupQuartersFlag > 0 && (
                    <Badge bg="primary" className="me-2">High Population Density</Badge>
                )}
                {nearbyFoodDeserts.LAhalfand10 > 0 && (
                    <Badge bg="warning" className="me-2">Low Access</Badge>
                )}
                {nearbyFoodDeserts.LA1and20 > 0 && (
                    <Badge bg="danger" className="me-2">Severe Low Access</Badge>
                )}
                {nearbyFoodDeserts.LALOWI1_20 > 0 && (
                    <Badge bg="success" className="me-2">Low Income, Low Access Population</Badge>
                )}
                {nearbyFoodDeserts.HUNVFlag > 0 && (
                    <Badge bg="dark" className="me-2">Low Vehicle Access</Badge>
                )}
            </div>
        );
    };

    /* global google */
    const FoodDesertMap = ({ foodDesertConnection, center = { lat: 30.2672, lng: -97.7431 }, height = '400px' }) => {
        useEffect(() => {
            function initMap() {
                if (foodDesertLoading) {
                    return null;
                }
                if (typeof google === 'undefined' || !google.maps) {
                    console.error('Google Maps API is not loaded.');
                    return;
                }

                var map = new google.maps.Map(document.getElementById(`map-${foodDesertConnection.CensusTract}`), {
                    zoom: 10,
                    center: center,
                });

                try {
                    const feature = JSON.parse(foodDesertConnection.coordinates);

                    if (!feature || !feature.geometry) {
                        console.error('Invalid GeoJSON feature.');
                        return;
                    }

                    // Load the GeoJSON feature
                    map.data.addGeoJson({
                        type: 'FeatureCollection',
                        features: [feature]
                    });

                    // Style the specific tract feature
                    map.data.setStyle({
                        fillColor: 'blue',
                        strokeWeight: 1,
                        fillOpacity: 0.2,
                        strokeColor: 'black',
                    });

                    // Highlight the specific tract if available
                    map.data.forEach((feature) => {
                        const bounds = new google.maps.LatLngBounds();
                        feature.getGeometry().forEachLatLng((latLng) => {
                            bounds.extend(latLng);
                        });
                        map.fitBounds(bounds);
                    });

                } catch (error) {
                    console.error('Failed to parse nearestFoodDesert as JSON.', error);
                }
            }

            const loadGoogleMapsScript = () => {
                if (!window.google) {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
                    script.async = true;
                    script.defer = true;
                    script.id = 'google-maps-script';
                    document.head.appendChild(script);
                    script.onload = initMap;
                } else {
                    initMap(); // Initialize if the script is already loaded
                }
            };

            loadGoogleMapsScript();
        }, [foodDesertConnection, center]);
        return <div id={`map-${foodDesertConnection.CensusTract}`} style={{ width: '100%', height: height }} className="rounded"></div>;
    };


    useEffect(() => {
        if (!initialProvider) {
            fetchServiceProviderDetail();
        } else {
            setLoading(false);
        }
    }, [serviceProviderID]);

    // Fetch food desert and food resource connections after service provider loads
    useEffect(() => {
        if (foodDesertConnectionID) fetchFoodDesertConnection();
    }, [foodDesertConnectionID]);

    useEffect(() => {
        if (foodResourceConnectionID) fetchFoodResourceConnection();
    }, [foodResourceConnectionID]);

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Container className="my-5">
            <Row>
                {/* Left Column: Basic Info and Details */}
                <Col md={8}>
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Row className="align-items-center">
                                {loading ? <Col md={6} className="text-center">
                                    <Skeleton height={100} />
                                </Col> :
                                    <Col md={6} className="text-center">
                                        <Image
                                            src={serviceProvider.resource_name == 'HHSC benefits office' ? '/images/hhscLogo.png' : serviceProvider.logo}
                                            alt={`${serviceProvider.resource_name} logo`}
                                            fluid
                                            className="mb-3"
                                            style={{ width: '100%', height: 'auto' }}
                                            test-id="service-provider-img"
                                        />
                                    </Col>
                                }
                                {loading ?
                                    <Col md={6}>
                                        <Skeleton height={100} />
                                    </Col> :
                                    <Col md={6}>
                                        <Card className="shadow-sm p-3 rounded">
                                            <Card.Title as="h2">{serviceProvider.resource_name}</Card.Title>
                                            <div className="my-3">
                                                <BsGeoAlt className="me-2 mb-1" />
                                                <span>
                                                    {`${serviceProvider.physical_address}`}
                                                </span>
                                            </div>
                                            <div className="my-3">
                                                <FaMap className="me-2 mb-1" />
                                                <span>{serviceProvider.county + ' County' || 'N/A'}</span>
                                            </div>
                                            <div className="my-3 d-flex align-items-center">
                                                <BsClock className="me-2 mb-1" />
                                                {renderOperatingHoursTable(serviceProvider.operating_hours) || 'N/A'}
                                            </div>
                                        </Card>
                                    </Col>
                                }
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Below Main Card: Closest Food Resource */}
                    <Card className="shadow mb-4" id="food-resource-card" onClick={() => navigate('/food-resources/' + foodResourceConnectionID)} style={{ cursor: 'pointer' }}>
                        <Card.Body>
                            <Card.Title className="text-center" as="h3">Closest Food Resource</Card.Title>
                            {foodResourceLoading
                                ?
                                <Card
                                    className="mb-3 shadow"
                                    style={{ borderColor: '#dee2e6', borderWidth: '1px' }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderBottom: '1px solid #dee2e6',
                                        }}
                                    >
                                        <Skeleton width="100%" height="200px" />
                                    </div>
                                    <Card.Body style={{ backgroundColor: '#f8f9fa' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <Skeleton width="80%" height="30px" />
                                        </div>
                                        <Card.Text>
                                            <BsGeoAltFill className="me-2" />
                                            <Skeleton width="50%" height="20px" />
                                        </Card.Text>
                                        <Card.Text>
                                            <BsBriefcaseFill className="me-2" />
                                            <Skeleton width="50%" height="20px" />
                                        </Card.Text>
                                        <Card.Text>
                                            <BsTelephoneFill className="me-2" />
                                            <Skeleton width="50%" height="20px" />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                :
                                <Card
                                    className="mb-3 shadow"
                                    style={{ borderColor: '#dee2e6', borderWidth: '1px' }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderBottom: '1px solid #dee2e6',
                                        }}
                                    >
                                        {/* Image with fade effect */}
                                        {foodResourceConnection.featured_image && (
                                            <Image
                                                src={foodResourceConnection.featured_image}
                                                alt={`${foodResourceConnection.name} photo`}
                                                fluid
                                                className="rounded-top"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover', // Ensures image covers the container
                                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                }}
                                            />
                                        )}

                                        {/* Gradient fade-out effect on the image */}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: '0',
                                                left: '0',
                                                right: '0',
                                                height: '40px',
                                                background:
                                                    'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3))',
                                            }}
                                        ></div>
                                    </div>
                                    <Card.Body style={{ backgroundColor: '#f8f9fa' }}>
                                        <Card.Title as="h5">{foodResourceConnection.name}</Card.Title>
                                        <Card.Text>
                                            <BsGeoAltFill className="me-2" />
                                            {`${foodResourceConnection.address}`}
                                        </Card.Text>
                                        <Card.Text>
                                            <BsBriefcaseFill className="me-2" />
                                            {foodResourceConnection.main_category}
                                        </Card.Text>
                                        <Card.Text>
                                            <BsTelephoneFill className="me-2" />
                                            {foodResourceConnection.phone || 'N/A'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            }

                        </Card.Body>
                    </Card>
                </Col>


                {/* Right Column: Contact Info */}
                <Col md={4}>
                    <Card className="shadow-sm mb-4">
                        <Card.Body>
                            <Card.Title className="text-center" as="h3">Contact Information</Card.Title>
                            <ListGroup>
                                {loading ?
                                    <ListGroup.Item>
                                        <Skeleton width="100%" height="20px" />
                                    </ListGroup.Item>
                                    :
                                    <ListGroup.Item id="service-provider-phone">
                                        <BsTelephone className="me-2" />
                                        {"Phone: " + formatPhoneNumber(serviceProvider.phone_number) || 'N/A'}
                                    </ListGroup.Item>
                                }
                                {loading ?
                                    <ListGroup.Item>
                                        <Skeleton width="100%" height="20px" />
                                    </ListGroup.Item>
                                    :
                                    <ListGroup.Item>
                                        <FaFax className="me-2" />
                                        {"Fax: " + formatPhoneNumber(serviceProvider.fax_number) || 'N/A'}
                                    </ListGroup.Item>
                                }
                                {loading ?
                                    <ListGroup.Item>
                                        <Skeleton width="100%" height="20px" />
                                    </ListGroup.Item>
                                    :
                                    <ListGroup.Item>
                                        <FaDeaf className="me-2" />
                                        {"TDD: " + formatPhoneNumber(serviceProvider.tdd_number) || 'N/A'}
                                    </ListGroup.Item>
                                }
                                {loading ?
                                    <ListGroup.Item>
                                        <Skeleton width="100%" height="20px" />
                                    </ListGroup.Item>
                                    :
                                    <ListGroup.Item>
                                        <BsGlobe className="me-2" />
                                        {/* Only render the anchor tag if the service provider has a website */}
                                        {serviceProvider.website != null && (
                                            <a href={serviceProvider.website} target="_blank" rel="noopener noreferrer">
                                                {serviceProvider.website}
                                            </a>
                                        )}
                                        {/* Else, just put a plain N/A string */}
                                        {!serviceProvider.website && 'N/A'}
                                    </ListGroup.Item>
                                }
                                {loading ?
                                    <ListGroup.Item>
                                        <Skeleton width="100%" height="20px" />
                                    </ListGroup.Item> :
                                    <ListGroup.Item className="text-center p-4">
                                        <a href={serviceProvider.resource_name == 'HHSC benefits office' ? "https://www.facebook.com/TexasHHSC/" : serviceProvider.facebook} target="_blank" rel="noopener noreferrer">
                                            <BsFacebook className="fs-2 text-primary me-5" />
                                        </a>
                                        <a href={serviceProvider.resource_name == 'HHSC benefits office' ? "https://x.com/TexasHHSC" : serviceProvider.twitter} target="_blank" rel="noopener noreferrer">
                                            <BsTwitter className="fs-2 text-primary me-5" />
                                        </a>
                                        <a href={serviceProvider.resource_name == 'HHSC benefits office' ? "https://www.instagram.com/texashhsc/" : serviceProvider.instagram} target="_blank" rel="noopener noreferrer">
                                            <BsInstagram className="fs-2 text-primary" />
                                        </a>
                                    </ListGroup.Item>
                                }
                            </ListGroup>
                        </Card.Body>
                    </Card>

                    {/* Right Column: Closest Food Desert Card */}
                    <Card className="shadow-sm mb-4" onClick={() => navigate('/food-deserts/' + foodDesertConnectionID)} style={{ cursor: 'pointer' }}>
                        <Card.Body>
                            <Card.Title as="h3" className="text-center">Closest Food Desert</Card.Title>
                            {foodDesertLoading
                                ?
                                <ListGroup>
                                    <Skeleton width="100%" height="400px" />
                                    <ListGroup.Item>
                                        <FaMap className="me-2 mb-1" />
                                        <Skeleton width="80%" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsPeopleFill className="me-2" />
                                        <Skeleton width="80%" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsCash className="me-2" />
                                        <Skeleton width="80%" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsCash className="me-2" />
                                        <Skeleton width="80%" />
                                    </ListGroup.Item>
                                    <Skeleton width="80%" />
                                </ListGroup>
                                :
                                <ListGroup>
                                    {/* only render the map if there is a nearest food desert */}
                                    <FoodDesertMap id="map-${foodDesertConnection.CensusTract}" foodDesertConnection={foodDesertConnection} />
                                    <ListGroup.Item>
                                        <FaMap className="me-2 mb-1" />
                                        <span>{ foodDesertConnection.County}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsPeopleFill className="me-2" />
                                        {"Population: " + foodDesertConnection.POP2010.toLocaleString('en-US')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsCash className="me-2" />
                                        {"Median Income - " + `$${foodDesertConnection.MedianFamilyIncome.toLocaleString('en-US')}`}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <BsCash className="me-2" />
                                        {"Poverty Rate - " + foodDesertConnection.PovertyRate.toFixed(2) + '%'}
                                    </ListGroup.Item>
                                    <TagsComponent nearbyFoodDeserts={foodDesertConnection} />
                                </ListGroup>
                            }
                        </Card.Body>
                    </Card>
                </Col>

                <Container className="mb-5">
                    <div className="mt-4">
                        <Card className="shadow-sm p-3 rounded">
                            <Card.Title className="text-center" as="h3">Location Map</Card.Title>
                            {loading ?
                                <div style={{ width: '100%', height: '400px' }}>
                                    <Skeleton width="100%" height="400px" />
                                </div>
                                :
                                <div style={{ width: '100%', height: '400px' }}>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        src={`https://www.google.com/maps/embed/v1/place?key=${encodeURIComponent(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)}&q=place_id:${encodeURIComponent(
                                            `${serviceProvider.place_id}`
                                        )}`}
                                        allowFullScreen
                                        aria-hidden="false"
                                        tabIndex="0"
                                        className="rounded"
                                    ></iframe>
                                </div>
                            }
                        </Card>
                    </div>
                </Container>
            </Row>
        </Container >
    );
};

export default ServiceProviderDetail;
