import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Pagination } from 'react-bootstrap';
import './foodDeserts.css';
import { useNavigate } from 'react-router-dom';
import { BsGlobe, BsMapFill, BsPeopleFill, BsCashStack, BsBuildings, BsTree } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FoodDeserts = () => {
    const navigate = useNavigate();

    // State variables
    const [foodDeserts, setFoodDeserts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9; // For a 3x3 grid
    const [county, setCounty] = useState(''); // New County search state
    const [sortOption, setSortOption] = useState('');
    const [debouncedCounty, setDebouncedCounty] = useState(county); // Debounced County value
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [filterOptions, setFilterOptions] = useState({
        county: '',
        is_rural: '',
    });
    const [debouncedFilterOptions, setDebouncedFilterOptions] = useState(filterOptions);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Debounce County search query
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedCounty(county);
            setCurrentPage(1); // Reset to first page on County search
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [county]);

    // Debounce filter options
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedFilterOptions(filterOptions);
            setCurrentPage(1); // Reset to first page on filter change
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [filterOptions]);

    // Fetch food deserts data from backend API
    useEffect(() => {
        fetchFoodDeserts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, debouncedCounty, sortOption, debouncedFilterOptions]);

    const fetchFoodDeserts = async () => {
        setLoading(true);
        try {
            // Build query parameters
            const params = new URLSearchParams();
            params.append('page', currentPage);
            params.append('per_page', itemsPerPage);

            if (debouncedCounty) {
                params.append('county', debouncedCounty);
            }
            if (sortOption) {
                const [sortBy, order] = sortOption.split('|');
                params.append('sort_by', sortBy);
                params.append('order', order);
            }

            if (debouncedFilterOptions.county) {
                params.append('county', debouncedFilterOptions.county);
            }
            if (debouncedFilterOptions.is_rural !== '') {
                params.append('is_rural', debouncedFilterOptions.is_rural);
            }
            // if (debouncedFilterOptions.is_urban !== '') {
            //     params.append('is_urban', debouncedFilterOptions.is_urban);
            // }

            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-deserts?${params.toString()}`;
            const response = await fetch(url);
            const data = await response.json();
            setFoodDeserts(data.food_deserts);
            setTotalPages(data.pagination.total_pages);
            setTotalItems(data.pagination.total_items);
        } catch (error) {
            console.error('Error fetching food deserts:', error);
            setError('Failed to fetch food deserts.');
        } finally {
            setLoading(false);
        }
    };

    /* global google */
    const FoodDesertMap = ({ foodDesertConnection, center = { lat: 30.2672, lng: -97.7431 }, height = '200px' }) => {
        useEffect(() => {
            let isMapInitialized = false;
            function initMap() {
                if (typeof google === 'undefined' || !google.maps) {
                    console.error('Google Maps API is not loaded.');
                    return;
                }

                var map = new google.maps.Map(document.getElementById(`map-${foodDesertConnection.CensusTract}`), {
                    zoom: 10,
                    center: center,
                });

                try {
                    const feature = JSON.parse(foodDesertConnection.coordinates);

                    if (!feature || !feature.geometry) {
                        console.error('Invalid GeoJSON feature.');
                        return;
                    }

                    // Load the GeoJSON feature
                    map.data.addGeoJson({
                        type: 'FeatureCollection',
                        features: [feature]
                    });

                    // Style the specific tract feature
                    map.data.setStyle({
                        fillColor: 'blue',
                        strokeWeight: 1,
                        fillOpacity: 0.2,
                        strokeColor: 'black',
                    });

                    // Highlight the specific tract if available
                    map.data.forEach((feature) => {
                        const bounds = new google.maps.LatLngBounds();
                        feature.getGeometry().forEachLatLng((latLng) => {
                            bounds.extend(latLng);
                        });
                        map.fitBounds(bounds);
                    });
                    isMapInitialized = true;
                } catch (error) {
                    console.error('Failed to parse nearestFoodDesert as JSON.', error);
                }
            }

            const loadGoogleMapsScript = () => {
                if (!window.google) {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
                    script.async = true;
                    script.defer = true;
                    script.id = 'google-maps-script';
                    document.head.appendChild(script);
                    // script.onload = initMap;
                    script.onload = () => {
                        if (!isMapInitialized) initMap();
                    };
                } else {
                    if (window.google) {
                        initMap(); // Initialize if the script is already loaded
                    }
                }
            };

            loadGoogleMapsScript();
        }, [foodDesertConnection, center]);
        return <div id={`map-${foodDesertConnection.CensusTract}`} style={{ width: '100%', height: height, marginBottom: 10 }} className="rounded"></div>;
    };

    // Event handlers
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCountyChange = (e) => {
        setCounty(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
        setCurrentPage(1); // Reset to first page on sort change
    };

    const highlightSearchTerm = (text, term) => {
        if (!term) return text; // If no search term, return original text
        const regex = new RegExp(`(${term})`, 'gi'); // Case-insensitive match
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === term.toLowerCase() ? (
                <span key={index} className="highlight">
                    {part}
                </span>
            ) : (
                part
            )
        );
    };


    const handleFilterChange = (e) => {
        setFilterOptions({
            ...filterOptions,
            [e.target.name]: e.target.value,
        });
    };

    // Render pagination items
    const renderPaginationItems = () => {
        const items = [];

        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(currentPage + 1, totalPages);

        if (currentPage === 1) {
            endPage = Math.min(3, totalPages);
        } else if (currentPage === totalPages) {
            startPage = Math.max(totalPages - 2, 1);
        }

        // First page button
        items.push(
            <Pagination.First
                key="first"
                id="first-page-button"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
            />
        );

        // Previous page button
        items.push(
            <Pagination.Prev
                key="prev"
                id="prev-page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
        );

        // Page numbers
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={currentPage === number}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        // Next page button
        items.push(
            <Pagination.Next
                key="next"
                id="next-page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        );

        // Last page button
        items.push(
            <Pagination.Last
                key="last"
                id="last-page-button"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
            />
        );

        return items;
    };

    return (
        <div className="food-deserts-page">
            {/* Header Section */}
            <section id="title">
                <div className="title-section">
                    <h1>Food Deserts</h1>
                    <p>
                        Explore the food deserts identified across Texas, where access to affordable, nutritious food is limited.
                    </p>
                </div>
            </section>

            {/* Search and Filter Section */}
            <section id="search-filter-section">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col md={6} lg={4}>
                            <InputGroup>
                                <InputGroup.Text>
                                    <BsMapFill />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by county"
                                    value={county}
                                    onChange={handleCountyChange}
                                />
                            </InputGroup>
                        </Col>
                        {/*sorting not working rn due to some db issue*/}
                        {/* <Col md={3} lg={2}>
              <Form.Select value={sortOption} onChange={handleSortChange}>
                <option value="">Sort By</option>
                <option value="PovertyRate|asc">Poverty Rate (Low - High)</option>
                <option value="PovertyRate|desc">Poverty Rate (High - Low)</option>
              </Form.Select>
            </Col> */}
                        <Col md={3} lg={2}>
                            <Form.Select value={sortOption} onChange={handleSortChange}>
                                <option value="">Sort By</option>
                                <option value="distance|asc">Distance (closest)</option>
                                <option value="distance|desc">Distance (furthest)</option>
                                <option value="resource_name|asc">Name (A-Z)</option>
                                <option value="resource_name|desc">Name (Z-A)</option>
                                <option value="county|asc">County (A-Z)</option>
                                <option value="county|desc">County (Z-A)</option>
                            </Form.Select>
                        </Col>
                        <Col md={3} lg={2}>
                            <Form.Control
                                type="text"
                                placeholder="Filter by County"
                                name="county"
                                value={filterOptions.county}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3} lg={2}>
                            <Form.Select
                                name="is_rural"
                                value={filterOptions.is_rural}
                                onChange={handleFilterChange}
                            >
                                <option value="">Filter by Type</option>
                                <option value="1">Rural</option>
                                <option value="0">Urban</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Error Message */}
            {error && <div className="error">{error}</div>}

            {/* Grid Section */}
            <section id="grid-section">
                {loading
                    ? // Show skeletons when loading
                    Array.from({ length: itemsPerPage }).map((_, index) => (
                        <div id="desert-card" key={index}>
                            <div id="card-content">
                                <div id="card-details">
                                    <Skeleton height={200} width="100%" />
                                    <div style={{ marginTop: '10px' }}>
                                        <BsGlobe className="icon" />
                                        <Skeleton width="50%" height={30} />
                                    </div>
                                    <div style={{ marginTop: '10px', alignItems: 'center' }}>
                                        <BsPeopleFill className="icon" />
                                        <Skeleton width="50%" height={30} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <BsCashStack className="icon" />
                                        <Skeleton width="50%" height={30} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <BsMapFill className="icon" />
                                        <Skeleton width="50%" height={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    : // Show actual data
                    foodDeserts.map((desert) => (
                        <div
                            id="desert-card"
                            key={desert.id}
                            onClick={() => navigate(`/food-deserts/${desert.id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div id="card-content">
                                <h5 id="card-title">{desert.name}</h5>
                                <div id="card-details">
                                    <div style={{ height: '200px', width: '100%', marginBottom: '10px' }}>
                                        <FoodDesertMap className="food-desert-map" id={`map-${desert.CensusTract}`} foodDesertConnection={desert} />
                                    </div>
                                    <p id="desert-county">
                                        <BsGlobe className="icon" />
                                        <strong>County:</strong> &nbsp;
                                        {highlightSearchTerm(desert.County, debouncedCounty)}
                                    </p>
                                    <p id="desert-population">
                                        <BsPeopleFill className="icon" />
                                        <strong>Population: </strong> &nbsp;
                                        {new Intl.NumberFormat().format(desert.POP2010)}
                                    </p>
                                    <p id="desert-income">
                                        <BsCashStack className="icon" />
                                        <strong>Median Income: </strong> &nbsp;
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(desert.MedianFamilyIncome)}
                                    </p>
                                    <p id="desert-poverty">
                                        <BsMapFill className="icon" />
                                        <strong>Poverty Rate: </strong> &nbsp;
                                        {parseFloat(desert.PovertyRate).toFixed(2)}%
                                    </p>
                                    <p id="desert-area-type">
                                        {desert.Urban ? <BsBuildings className="icon" /> : <BsTree className="icon" />}
                                        <strong>Area Type: </strong> &nbsp;
                                        {desert.Urban ? 'Urban' : 'Rural'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </section>
           

            {/* Pagination Section */}
            <section id="pagination-section">
                <Container>
                    <Row className="justify-content-center">
                        <Pagination>{renderPaginationItems()}</Pagination>
                    </Row>
                    <Row className="justify-content-center">
                        <p>
                            Page {currentPage} of {totalPages}, showing{' '}
                            {foodDeserts.length} of {totalItems} instances
                        </p>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default FoodDeserts;
