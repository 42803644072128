import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
    Image,
} from 'react-bootstrap';
import {
    BsTelephone,
    BsGeoAlt,
    BsClock,
    BsGlobe,
    BsStarFill,
    BsPeopleFill,
    BsCashStack,
    BsMapFill,
    BsBuildings,
    BsTree,
    BsCardChecklist,
    BsHouse,
} from 'react-icons/bs';
import { FaMap } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FoodResourceDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const initialResource = location.state?.resource;
    const [foodResource, setFoodResource] = useState(initialResource);
    const [loading, setLoading] = useState(!initialResource);
    const [error, setError] = useState(null);
    const [foodDesert, setFoodDesert] = useState(null);
    const [serviceProvider, setServiceProvider] = useState(null);
    const [desertLoading, setDesertLoading] = useState(true);
    const [providerLoading, setProviderLoading] = useState(true);

    const fetchFoodResourceDetail = async () => {
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/food-resources/${id}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch food resource details.');
            const data = await response.json();
            setFoodResource(data);
            fetchNearestConnections(data.nearest_food_desert_id, data.nearest_service_provider_id);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchNearestConnections = async (desertId, providerId) => {
        try {
            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }
            const urlDesert = `${baseURL || ""}/api/food-deserts/${desertId}`;
            const urlProvider = `${baseURL || ""}/api/service-providers/${providerId}`;
            const desertResponse = await fetch(urlDesert);
            const providerResponse = await fetch(urlProvider);
            if (desertResponse.ok) setFoodDesert(await desertResponse.json());
            if (providerResponse.ok) setServiceProvider(await providerResponse.json());
        } catch (error) {
            console.error(error);
        } finally {
            setDesertLoading(false);
            setProviderLoading(false);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (cleaned.length !== 10) return 'Invalid phone number';
        return `(${cleaned.substring(0, 3)})-${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
    };

    /* global google */
    const FoodDesertMap = ({ foodDesertConnection, center = { lat: 30.2672, lng: -97.7431 }, height = '400px' }) => {
        useEffect(() => {
            function initMap() {
                if (typeof google === 'undefined' || !google.maps) {
                    console.error('Google Maps API is not loaded.');
                    return;
                }

                var map = new google.maps.Map(document.getElementById(`map-${foodDesertConnection.CensusTract}`), {
                    zoom: 10,
                    center: center,
                });

                try {
                    const feature = JSON.parse(foodDesertConnection.coordinates);

                    if (!feature || !feature.geometry) {
                        console.error('Invalid GeoJSON feature.');
                        return;
                    }

                    // Load the GeoJSON feature
                    map.data.addGeoJson({
                        type: 'FeatureCollection',
                        features: [feature]
                    });

                    // Style the specific tract feature
                    map.data.setStyle({
                        fillColor: 'blue',
                        strokeWeight: 1,
                        fillOpacity: 0.2,
                        strokeColor: 'black',
                    });

                    // Highlight the specific tract if available
                    map.data.forEach((feature) => {
                        const bounds = new google.maps.LatLngBounds();
                        feature.getGeometry().forEachLatLng((latLng) => {
                            bounds.extend(latLng);
                        });
                        map.fitBounds(bounds);
                    });

                } catch (error) {
                    console.error('Failed to parse nearestFoodDesert as JSON.', error);
                }
            }

            const loadGoogleMapsScript = () => {
                if (!window.google) {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
                    script.async = true;
                    script.defer = true;
                    script.id = 'google-maps-script';
                    document.head.appendChild(script);
                    script.onload = initMap;
                } else {
                    initMap(); // Initialize if the script is already loaded
                }
            };

            loadGoogleMapsScript();
        }, [foodDesertConnection, center]);
        return <div id={`map-${foodDesertConnection.CensusTract}`} style={{ width: '100%', height: height, marginBottom: 10}} className="rounded"></div>;
    };

    useEffect(() => {
        if (!foodResource) fetchFoodResourceDetail();
    }, [id, foodResource]);

    if (loading) return <Skeleton count={5} />;
    if (error) return <div>{error}</div>;

    return (
        <Container className="my-5">
            <Row>
                {/* Main Food Resource Card with Image and Info */}
                <Col md={8}>
                    <Card className="shadow-sm mb-4">
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col md={5} className="text-center">
                                    <Image
                                        src={foodResource.name === 'H-E-B' ? '/images/hebLogo.png' : foodResource.featured_image}
                                        alt={`${foodResource.name} logo`}
                                        fluid
                                        className="mb-3"
                                    />
                                </Col>
                                <Col md={7}>
                                    <Card.Title as="h2" id="resource-detail-page-title">{foodResource.name}</Card.Title>
                                    <div className="my-2" id="resource-detail-page-address">
                                        <BsGeoAlt className="me-2 mb-1" />
                                        {foodResource.address}
                                    </div>
                                    <div className="my-2" id="resource-detail-page-categories">
                                        <FaMap className="me-2 mb-1" />
                                        {foodResource.categories || 'N/A'}
                                    </div>
                                    <div className="my-2" id="resource-detail-page-hours">
                                        <BsClock className="me-2 mb-1" />
                                        {foodResource.workday_timing || 'N/A'}
                                    </div>
                                    <div className="my-2" id="resource-detail-page-phone">
                                        <BsTelephone className="me-2 mb-1" />
                                        {formatPhoneNumber(foodResource.phone) || 'N/A'}
                                    </div>
                                    <div className="my-2" id="resource-detail-page-website">
                                        <BsGlobe className="me-2 mb-1" />
                                        {foodResource.website ? (
                                            <a href={foodResource.website} target="_blank" rel="noopener noreferrer">
                                                {foodResource.website}
                                            </a>
                                        ) : 'N/A'}
                                    </div>
                                    <div className="my-2" id="resource-detail-page-rating">
                                        <span>Rating: </span>
                                        {[...Array(5)].map((_, index) => (
                                            <BsStarFill
                                                key={index}
                                                className={index < Math.round(foodResource.rating) ? 'text-warning' : 'text-secondary'}
                                            />
                                        ))}
                                        <span className="ms-2">({foodResource.rating}/5)</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Description Section */}
                    <Card className="shadow-sm mb-4" id="resource-description-section">
                        <Card.Body>
                            <Card.Title as="h3">Description</Card.Title>
                            <p>{foodResource.description || 'No description available.'}</p>
                        </Card.Body>
                    </Card>

                    {/* Location Map */}
                    <Card className="shadow-sm" id="resource-location-map-section">
                        <Card.Body>
                            <Card.Title as="h3">Location Map</Card.Title>
                            <iframe
                                width="100%"
                                height="300"
                                frameBorder="0"
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${foodResource.place_id}`}
                                allowFullScreen
                                className="rounded"
                                id="location-map"
                            ></iframe>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Nearest Food Desert Card */}
                <Col md={4}>
                    <Card className="shadow-sm mb-4 text-center" id="resource-nearest-food-desert-section" style={{cursor: "pointer"}}>
                        <Card.Body>
                            <Card.Title as="h3">Nearest Food Desert</Card.Title>
                            {desertLoading ? (
                                <Skeleton count={5} />
                            ) : (
                                <div id="card-content" onClick={() => navigate(`/food-deserts/${foodDesert.id}`)}>
                                    <h5 id="card-title">{foodDesert.name}</h5>
                                    <div id="card-details">
                                        <FoodDesertMap id={`map-${foodDesert.CensusTract}`} foodDesertConnection={foodDesert}/>
                                        <p id="resource-detail-desert-address"><BsGlobe className="icon" /> <strong>County:&nbsp;</strong> {foodDesert.County}</p>
                                        <p id="resource-detail-desert-population"><BsPeopleFill className="icon" /> <strong>Population:&nbsp;</strong> {new Intl.NumberFormat().format(foodDesert.POP2010)}</p>
                                        <p id="resource-detail-desert-income"><BsCashStack className="icon" /> <strong>Median Income:&nbsp;</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(foodDesert.MedianFamilyIncome)}</p>
                                        <p id="resource-detail-desert-poverty"><BsMapFill className="icon" /> <strong>Poverty Rate:&nbsp;</strong> {parseFloat(foodDesert.PovertyRate).toFixed(2)}%</p>
                                        <p>{foodDesert.Urban ? <BsBuildings className="icon" /> : <BsTree className="icon" />} <strong>Area Type:&nbsp;</strong> {foodDesert.Urban ? 'Urban' : 'Rural'}</p>
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                {/* Nearest Service Provider Card */}
                <Col md={4}>
                    <Card className="shadow-sm mb-4 mt-4 text-center" id="resource-nearest-service-provider-section" style={{cursor: "pointer"}}
                        onClick={() => navigate(`/service-providers/${serviceProvider.id}`, { state: { serviceProvider } })}
                    >
                        <Card.Body>
                            <Card.Title as="h3">Nearest Service Provider</Card.Title>
                            {providerLoading ? (
                                <Skeleton count={5} />
                            ) : (
                                <div id="card-content" onClick={() => navigate(`/service-providers/${serviceProvider.id}`, { state: { serviceProvider } })}>
                                    <h5 id="card-title">{serviceProvider.resource_name}</h5>
                                    <div id="card-details">
                                        <p><BsCardChecklist className="icon" /> <strong>Benefits:</strong> {serviceProvider.benefit_programs}</p>
                                        <p><BsTelephone className="icon" /> <strong>Phone:</strong> {formatPhoneNumber(serviceProvider.phone_number) || 'N/A'}</p>
                                        <p><BsGlobe className="icon" /> <strong>Website:</strong> <a href={serviceProvider.website} target="_blank" rel="noopener noreferrer">{serviceProvider.website || 'N/A'}</a></p>
                                        <p><BsClock className="icon" /> <strong>Hours:</strong> {serviceProvider.operating_hours || 'N/A'}</p>
                                        <p><BsGeoAlt className="icon" /> <strong>Address:</strong> {serviceProvider.physical_address || 'N/A'}</p>
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FoodResourceDetail;
