import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Pagination,
} from 'react-bootstrap';
import './FoodResources.css';
import { useNavigate } from 'react-router-dom';
import {
  BsSearch,
  BsClock,
  BsGeoAlt,
  BsTelephone,
  BsGlobe,
  BsCardChecklist,
  BsHouse,
} from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FoodResources = () => {
  const navigate = useNavigate();

  // State variables
  const [foodResources, setFoodResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // For a 3x3 grid
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [sortOption, setSortOption] = useState('');
  const [city, setCity] = useState(''); // New city search state
  const [debouncedCity, setDebouncedCity] = useState(city); // Debounced city value
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setCurrentPage(1); // Reset to first page on search
    }, 500); // Adjust debounce delay as needed
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Debounce city search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCity(city);
      setCurrentPage(1); // Reset to first page on city search
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [city]);

  // Fetch data from backend API
  useEffect(() => {
    fetchFoodResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchQuery, sortOption, debouncedCity]);

  const fetchFoodResources = async () => {
    setLoading(true);
    try {
      // Build query parameters
      const params = new URLSearchParams();
      params.append('page', currentPage);
      params.append('per_page', itemsPerPage);

      if (debouncedSearchQuery) {
        params.append('search', debouncedSearchQuery);
      }
      if (sortOption) {
        const [sortBy, order] = sortOption.split('|');
        params.append('sort_by', sortBy);
        params.append('order', order);
      }
      if (debouncedCity) {
        params.append('city', debouncedCity);
      }

      const baseURL = process.env.REACT_APP_api_base_url;
      if (!baseURL && process.env.NODE_ENV !== 'production') {
        throw new Error('API base URL is not defined in .env file for local development.');
      }

      const url = `${baseURL || ""}/api/food-resources?${params.toString()}`;
      const response = await fetch(url);
      const data = await response.json();
      setFoodResources(data.food_resources);
      setTotalPages(data.pagination.total_pages);
      setTotalItems(data.pagination.total_items);
    } catch (error) {
      console.error('Error fetching food resources:', error);
      setError('Failed to fetch food resources.');
    } finally {
      setLoading(false);
    }
  };

  // Event handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const highlightSearchTerm = (text, term) => {
    if (!term) return text; // If no search term, return original text
    const regex = new RegExp(`(${term})`, 'gi'); // Case-insensitive match
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Render pagination items
  const renderPaginationItems = () => {
    const items = [];

    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(currentPage + 1, totalPages);

    // Adjust start and end pages if at the beginning or end
    if (currentPage === 1) {
      endPage = Math.min(3, totalPages);
    } else if (currentPage === totalPages) {
      startPage = Math.max(totalPages - 2, 1);
    }

    // First page button
    items.push(
      <Pagination.First
        key="first"
        id="first-page-button"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
    );

    // Previous page button
    items.push(
      <Pagination.Prev
        key="prev"
        id="prev-page-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );

    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={currentPage === number}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Next page button
    items.push(
      <Pagination.Next
        key="next"
        id="next-page-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    );

    // Last page button
    items.push(
      <Pagination.Last
        key="last"
        id="last-page-button"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return items;
  };

  return (
    <div className="food-resources-page">
      {/* Header Section */}
      <section id="title">
        <div className="title-section">
          <h1>Food Resources</h1>
          <p>
            Explore the food resources available across Texas that provide
            access to affordable, nutritious food.
          </p>
        </div>
      </section>

      {/* Search and Filter Section */}
      <section id="search-filter-section">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={6} lg={4}>
              <InputGroup>
                <InputGroup.Text>
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={4}>
              <InputGroup>
                <InputGroup.Text>
                  <BsGeoAlt />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search by city"
                  value={city}
                  onChange={handleCityChange}
                />
              </InputGroup>
            </Col>
            <Col md={3} lg={2}>
              <Form.Select value={sortOption} onChange={handleSortChange}>
                <option value="">Sort By</option>
                <option value="resource_name|asc">Name (A-Z)</option>
                <option value="resource_name|desc">Name (Z-A)</option>
                <option value="rating|desc">Rating</option>
              </Form.Select>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Error Message */}
      {error && <div className="error">{error}</div>}

      {/* Grid Section */}
      <section id="grid-section">
        {loading
          ? // Show skeletons when loading
            Array.from({ length: itemsPerPage }).map((_, index) => (
              <div id="provider-card" key={index}>
                <div id="card-content">
                  <h5 id="card-title">
                    <Skeleton width={200} />
                  </h5>
                  <div id="card-details">
                    <p>
                      <BsHouse className="icon" />
                      <Skeleton width="80%" />
                    </p>
                    <p>
                      <BsCardChecklist className="icon" />
                      <Skeleton width="80%" />
                    </p>
                    <p>
                      <BsTelephone className="icon" />
                      <Skeleton width="80%" />
                    </p>
                    <p>
                      <BsGlobe className="icon" />
                      <Skeleton width="80%" />
                    </p>
                    <p>
                      <BsClock className="icon" />
                      <Skeleton width="80%" />
                    </p>
                    <p>
                      <BsGeoAlt className="icon" />
                      <Skeleton width="80%" />
                    </p>
                  </div>
                </div>
              </div>
            ))
          : // Show actual data
            foodResources.map((resource) => (
              <div
                id="provider-card"
                key={resource.id}
                onClick={() => navigate(`/food-resources/${resource.place_id}`)}
                style={{ cursor: 'pointer' }}
              >
                <div id="card-content">
                  <h5 className="resource-card-title">{highlightSearchTerm(resource.name, debouncedSearchQuery)}</h5>
                  <div className="resource-card-details">
                    <div style={{marginBottom: '10px'}} className="resource-image">
                        <img 
                        src={resource.featured_image} 
                        alt="No Image Available"
                        loading="lazy"
                        style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '10px' }} 
                        />
                    </div>
                    <p className="resource-description">
                      <BsHouse className="icon" />
                      <strong>Resource Type: </strong> &nbsp;
                      {resource.categories}
                    </p>
                    <p className="resource-rating">
                      <BsCardChecklist className="icon" />
                      <strong>Rating: </strong> &nbsp;
                      {resource.rating}
                    </p>
                    <p className="resource-phone">
                      <BsTelephone className="icon" />
                      <strong>Contact Info: </strong> &nbsp;
                      {resource.phone}
                    </p>
                    <p className="resource-website">
                      <BsGlobe className="icon" />
                      <strong>Website: </strong> &nbsp;
                      <a
                        href={resource.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {resource.website}
                      </a>
                    </p>
                    <p className="resource-hours">
                      <BsClock className="icon" />
                      <strong>Service Hours: </strong> &nbsp;
                      {resource.workday_timing}
                    </p>
                    <p className="resource-address">
                      <BsGeoAlt className="icon" />
                      <strong>Address: </strong> &nbsp;
                      {highlightSearchTerm(resource.address, debouncedCity)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
      </section>

      {/* Pagination Section */}
      <section id="pagination-section">
        <Container>
          <Row className="justify-content-center">
            <Pagination>{renderPaginationItems()}</Pagination>
          </Row>
          <Row className="justify-content-center">
            <p>
              Page {currentPage} of {totalPages}, showing{' '}
              {foodResources.length} of {totalItems} instances
            </p>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default FoodResources;
