import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    Pagination,
} from 'react-bootstrap';
import './serviceProviders.css';
import { useNavigate } from 'react-router-dom';
import {
    BsSearch,
    BsClock,
    BsGeoAlt,
    BsTelephone,
    BsGlobe,
    BsCardChecklist,
    BsHouse,
} from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ServiceProviders = () => {
    const navigate = useNavigate();

    // State variables
    const [serviceProviders, setServiceProviders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9; // For a 3x3 grid
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const [sortOption, setSortOption] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        county: '',
        is_gov: '',
    });
    const [debouncedFilterOptions, setDebouncedFilterOptions] = useState(filterOptions);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userLocation, setUserLocation] = useState({ lat: null, lng: null });

    // Fetch user's location on component mount
    const fetchUserLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserLocation({ lat: latitude, lng: longitude });
                        resolve({ lat: latitude, lng: longitude });
                    },
                    (error) => {
                        console.error("Error fetching location:", error);
                        reject(error);
                    }
                );
            } else {
                reject(new Error("Geolocation not supported"));
            }
        });
    };

    // Debounce search query
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
            setCurrentPage(1); // Reset to first page on search
        }, 500); // Adjust debounce delay as needed
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    // Debounce filter options
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedFilterOptions(filterOptions);
            setCurrentPage(1); // Reset to first page on filter change
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [filterOptions]);

    // Fetch data from backend API
    useEffect(() => {
        fetchServiceProviders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, debouncedSearchQuery, sortOption, debouncedFilterOptions]);

    // Utility function to abbreviate operating hours
    const renderOperatingHoursTable = (hours) => {
        if (!hours) {
            return null;
        }
        const days = [
            { full: 'Monday', short: 'M' },
            { full: 'Tuesday', short: 'T' },
            { full: 'Wednesday', short: 'W' },
            { full: 'Thursday', short: 'Th' },
            { full: 'Friday', short: 'F' },
            { full: 'Saturday', short: 'Sa' },
            { full: 'Sunday', short: 'Su' },
        ];

        return (
            <table className="operating-hours-table">
                <tbody>
                    {days.map((day) => {
                        const regex = new RegExp(`${day.full}:\\s*([\\d:APM\\s–]+)`, 'i');
                        const match = hours.match(regex);
                        return (
                            <tr key={day.full}>
                                <td>{day.full}</td>
                                <td>{(match && match[1] != ' ') ? match[1] : 'Closed'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    // Function to format phone numbers
    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';

        // Remove any characters that are not digits
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the phone number has the correct length (10 digits for US numbers)
        if (cleaned.length !== 10) {
            return 'Invalid phone number';
        }

        // Format the phone number as (111)-111-1111
        const formatted = `(${cleaned.substring(0, 3)})-${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;

        return formatted;
    };

    const fetchServiceProviders = async () => {
        setLoading(true);
        try {
            // Build query parameters
            const params = new URLSearchParams();
            params.append('page', currentPage);
            params.append('per_page', itemsPerPage);

            if (debouncedSearchQuery) {
                params.append('search', debouncedSearchQuery);
            }
            if (sortOption) {
                const [sortBy, order] = sortOption.split('|');
                params.append('sort_by', sortBy);
                params.append('order', order);
            }
            if (debouncedFilterOptions.county) {
                params.append('county', debouncedFilterOptions.county);
            }
            if (debouncedFilterOptions.is_gov !== '') {
                params.append('is_gov', debouncedFilterOptions.is_gov);
            }

            // Include user location if sorting by distance
            if (sortOption.includes('distance')) {
                const location = await fetchUserLocation();
                params.append('user_lat', location.lat);
                params.append('user_lng', location.lng);
            }

            const baseURL = process.env.REACT_APP_api_base_url;
            if (!baseURL && process.env.NODE_ENV !== 'production') {
                throw new Error('API base URL is not defined in .env file for local development.');
            }

            const url = `${baseURL || ""}/api/service-providers?${params.toString()}`;
            const response = await fetch(url);
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error('Failed to fetch service providers.');
            }
            setServiceProviders(data.service_providers);
            setTotalPages(data.pagination.total_pages);
            setTotalItems(data.pagination.total_items);
        } catch (error) {
            // gracefull handle error in the future
            console.error('Error fetching service providers:', error);
            setError('Failed to fetch service providers.');
        } finally {
            setLoading(false);
        }
    };

    // Event handlers
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
        setCurrentPage(1); // Reset to first page on sort change
    };

    const handleFilterChange = (e) => {
        setFilterOptions({
            ...filterOptions,
            [e.target.name]: e.target.value,
        });
    };

    const highlightSearchTerm = (text, term) => {
        if (!term) return text; // If no search term, return original text
        const regex = new RegExp(`(${term})`, 'gi'); // Case-insensitive match
        return text.split(regex).map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} className="highlight">
              {part}
            </span>
          ) : (
            part
          )
        );
      };

    // Render pagination items
    const renderPaginationItems = () => {
        const items = [];

        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(currentPage + 1, totalPages);

        // Adjust start and end pages if at the beginning or end
        if (currentPage === 1) {
            endPage = Math.min(3, totalPages);
        } else if (currentPage === totalPages) {
            startPage = Math.max(totalPages - 2, 1);
        }

        // First page button
        items.push(
            <Pagination.First
                key="first"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
            />
        );

        // Previous page button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
        );

        // Page numbers
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={currentPage === number}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        // Next page button
        items.push(
            <Pagination.Next
                id = "page-next"
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        );

        // Last page button
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
            />
        );

        return items;
    };

    return (
        <div className="service-providers-page">
            {/* Header Section */}
            <section id="title">
                <div className="title-section">
                    <h1>Service Providers</h1>
                    <p>
                        Explore the identified service providers across Texas that provide
                        access to affordable, nutritious food.
                    </p>
                </div>
            </section>

            {/* Search and Filter Section */}
            <section id="search-filter-section">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col md={6} lg={4}>
                            <InputGroup>
                                <InputGroup.Text>
                                    <BsSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} lg={2}>
                            <Form.Select value={sortOption} onChange={handleSortChange}>
                                <option value="">Sort By</option>
                                <option value="distance|asc">Distance (closest)</option>
                                <option value="distance|desc">Distance (furthest)</option>
                                <option value="resource_name|asc">Name (A-Z)</option>
                                <option value="resource_name|desc">Name (Z-A)</option>
                                <option value="county|asc">County (A-Z)</option>
                                <option value="county|desc">County (Z-A)</option>
                            </Form.Select>
                        </Col>
                        <Col md={3} lg={2}>
                            <Form.Control
                                type="text"
                                placeholder="Filter by County"
                                name="county"
                                value={filterOptions.county}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3} lg={2}>
                            <Form.Select
                                name="is_gov"
                                value={filterOptions.is_gov}
                                onChange={handleFilterChange}
                            >
                                <option value="">Filter by Program</option>
                                <option value="1">Is Government Program</option>
                                <option value="0">3rd Party Program</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Error Message */}
            {error && <div className="error">{error}</div>}

            {/* Grid Section */}
            <section id="grid-section">
                {loading
                    ? // Show skeletons when loading
                    Array.from({ length: itemsPerPage }).map((_, index) => (
                        <div id="provider-card" key={index}>
                            <div id="card-content">
                                <h5 id="card-title">
                                    <Skeleton width={200} />
                                </h5>
                                <div id="card-details">
                                    <div>
                                        <BsHouse className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                    <div>
                                        <BsCardChecklist className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                    <div>
                                        <BsTelephone className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                    <div>
                                        <BsGlobe className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                    <div>
                                        <BsClock className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                    <div>
                                        <BsGeoAlt className="icon" />
                                        <Skeleton width="80%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    : // Show actual data
                    serviceProviders.map((provider) => (
                        <div
                            id="provider-card"
                            key={provider.id}
                            onClick={() => navigate(`/service-providers/${provider.id}`, { state: { provider } })}
                            style={{ cursor: 'pointer' }}
                        >
                            <div id="card-content">
                                <h5 id="card-title">{highlightSearchTerm(provider.resource_name, debouncedSearchQuery)}</h5>
                                <div id="card-details">
                                    <p>
                                        <BsCardChecklist className="icon" />
                                        <strong>Benefits:</strong> &nbsp;
                                        {provider.benefit_programs}
                                    </p>
                                    <p>
                                        <BsTelephone className="icon" />
                                        <strong>Phone:</strong> &nbsp; {formatPhoneNumber(provider.phone_number) || 'N/A'}
                                    </p>
                                    <p>
                                        <BsGlobe className="icon" />
                                        <strong>Website:</strong> &nbsp;
                                        <a
                                            href={provider.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {provider.website || 'N/A'}
                                        </a>
                                    </p>
                                    <p>
                                        <BsClock className="icon" />
                                        <strong>Hours:</strong> &nbsp;
                                        {renderOperatingHoursTable(provider.operating_hours) || 'N/A'}
                                    </p>
                                    <p>
                                        <BsGeoAlt className="icon" />
                                        <strong>Address:</strong> &nbsp; {provider.physical_address || 'N/A'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </section>

            {/* Pagination Section */}
            <section id="pagination-section">
                <Container>
                    <Row className="justify-content-center">
                        <Pagination>{renderPaginationItems()}</Pagination>
                    </Row>
                    <Row className="justify-content-center">
                        <p id="page-info">
                            Page {currentPage} of {totalPages}, showing{' '}
                            {serviceProviders.length} of {totalItems} instances
                        </p>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default ServiceProviders;
