// GlobalSearchBar.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './GlobalSearchBar.css';

const GlobalSearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (event) => {
    event.preventDefault();
    if (onSearch && query.trim()) {
      onSearch(query);
    }
  };

  return (
    <form className="global-search-bar" onSubmit={handleSearch}>
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button type="submit" className="btn btn-primary">Search</button>
    </form>
  );
};

GlobalSearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default GlobalSearchBar;
