import React, { useState, useEffect } from 'react';
import './GlobalNavbar.css';
import GlobalSearchBar from './search/GlobalSearchBar';

const GlobalNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Close menu when scrolling
    window.addEventListener('scroll', () => setMenuOpen(false));

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', () => setMenuOpen(false));
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSearch = (query) => {
    console.log("Search query:", query);
    // Implement your search logic here
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`} id="mainNavBar">
      <a href="/" className="brand">FOOD OASIS TEXAS</a>
      <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`nav-links ${menuOpen ? 'open' : 'closed'}`}>
        <hr className={`links-hr ${menuOpen ? 'open' : 'closed'}`}/>
        <a href="/about-us">About Us</a>
        <hr className={`links-hr ${menuOpen ? 'open' : 'closed'}`}/>
        <a href="/food-deserts">Food Deserts</a>
        <hr className={`links-hr ${menuOpen ? 'open' : 'closed'}`}/>
        <a href="/food-resources">Food Resources</a>
        <hr className={`links-hr ${menuOpen ? 'open' : 'closed'}`}/>
        <a href="/service-providers">Service Providers</a>
      </div>
      <GlobalSearchBar onSearch={handleSearch} />
    </nav>
  );
};

export default GlobalNavbar;
